import log from "loglevel";

export const isDevEnv = process.env.NODE_ENV === "development";

interface DisclaimerProp {
    reference?: string;
    text: string;
    key?: string;
}

export const setupInitialConfiguration = () => {
    fetchAsset();
    if (isDevEnv) {
        setBackendBaseUrl("http://localhost:3000");

        // default disclaimer for local
        setDisclaimerText(defaultDisclaimer);
    } else {
        setBackendBaseUrl(window.location.protocol + "//api." + window.location.hostname);
        setDisclaimerText([]);
    }
};

export let backendBaseUrl: string;
export const setBackendBaseUrl = (url: string) => {
    backendBaseUrl = url;
};

export let organiser: boolean | undefined;
export const setOrganiser = (isOrganiser: boolean | undefined) => {
    organiser = isOrganiser;
};

export let disclaimerText: DisclaimerProp[];
export const setDisclaimerText = (dis: DisclaimerProp[]) => {
    disclaimerText = dis;
};

export let eventName: string;
export const setEventName = (name: string) => {
    eventName = name;
};
export let userName: string;
export const setUserName = (name: string) => {
    userName = name;
};

export let isVideoBackgroundEnabled: boolean | undefined;
export const setVideoBackgroundEnabled = (isVideoBackground: boolean | undefined) => {
    isVideoBackgroundEnabled = isVideoBackground;
};

const defaultDisclaimer = [
    {
        reference: "1.",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet incidunt aut voluptatum autem voluptates ea numquam cupiditate deleniti unde",
        key: "firstPlaceholder"
    },
    {
        reference: "2.",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet incidunt aut voluptatum autem voluptates ea numquam cupiditate deleniti unde",
        key: "secondPlaceholder"
    },
    {
        reference: "12.",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet incidunt aut voluptatum autem voluptates ea numquam cupiditate deleniti unde",
        key: "thirdPlaceholder"
    },
    {
        reference: "§",
        text: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet incidunt aut voluptatum autem voluptates ea numquam cupiditate deleniti unde",
        key: "fourthPlaceholder"
    }];

const fetchAsset = () => {
    fetch("/background_asset_config.json", {
        cache: "no-cache"
    }).then((response) => {
        response.json().then((json) => {
            setVideoBackgroundEnabled(json.isVideoBackground);
        }).catch(reason => log.warn("error while fetching config: %o", reason));
    }
    );
};
