/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.6-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    givenName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    corporation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    admin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    organiser?: boolean;
}

/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'givenName': !exists(json, 'givenName') ? undefined : json['givenName'],
        'familyName': !exists(json, 'familyName') ? undefined : json['familyName'],
        'corporation': !exists(json, 'corporation') ? undefined : json['corporation'],
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'organiser': !exists(json, 'organiser') ? undefined : json['organiser'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'givenName': value.givenName,
        'familyName': value.familyName,
        'corporation': value.corporation,
        'admin': value.admin,
        'organiser': value.organiser,
    };
}

