/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.6-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailBounceDto
 */
export interface EmailBounceDto {
    /**
     * 
     * @type {number}
     * @memberof EmailBounceDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailBounceDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailBounceDto
     */
    message?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailBounceDto
     */
    created?: Date;
}

/**
 * Check if a given object implements the EmailBounceDto interface.
 */
export function instanceOfEmailBounceDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailBounceDtoFromJSON(json: any): EmailBounceDto {
    return EmailBounceDtoFromJSONTyped(json, false);
}

export function EmailBounceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailBounceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
    };
}

export function EmailBounceDtoToJSON(value?: EmailBounceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'message': value.message,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
    };
}

