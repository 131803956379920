import { AlertLayer, AlertLayerProps, Container, ContainerGutter, ContainerPadding, CTA, LayerWrapper, Text, TextTag, TokenTextAppearance, withFocusLock } from "@volkswagen-onehub/components-core";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { ErrorSeverity, WithErrorDisplayProps } from "./types";

export function withD6ErrorHandling<P extends WithErrorDisplayProps = WithErrorDisplayProps>(WrappedComponent: React.ComponentType<P>) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component";

    const ComponentWithErrorHandling = (props: Omit<P, keyof WithErrorDisplayProps>) => {
        const [errorState, setErrorState] = useState<{ message: string; severity: ErrorSeverity } | undefined>(undefined);
        const onErrorHandler = (message: string, severity?: ErrorSeverity): void => {
            setErrorState({ message, severity: severity ? severity : "error" });
        };
        const AlertLayerWithFocusLock = withFocusLock<AlertLayerProps>(AlertLayer);
        return (
            <>
                <WrappedComponent {...(props as P)} onError={onErrorHandler} />

                {errorState?.severity === "error" ? (
                    <LayerWrapper contentCentered>
                        <AlertLayerWithFocusLock active>
                            <Container
                                gutter={ContainerGutter.static400}
                                horizontalAlign="center"
                                padding={ContainerPadding.static450}
                                verticalAlign="center"
                                wrap="always"
                                stretchContent>
                                <Text bold appearance={TokenTextAppearance.headline500} tag={TextTag.h2}>
                                    <Trans i18nKey="framework.errors.backendcomm.title"> Backend communication failure </Trans>
                                </Text>
                                <Text>
                                    <Trans i18nKey="framework.errors.backendcomm.message"> Calling backend functions failed.This should not happen.Please report the error below: </Trans>
                                    <br />
                                    {errorState.message}
                                </Text>
                                <CTA onClick={() => setErrorState(undefined)} tag="button" emphasis="primary" stretchContent>
                                    <Trans i18nKey="framework.errors.backendcomm.okButton"> Ok </Trans>
                                </CTA>
                            </Container>
                        </AlertLayerWithFocusLock>
                    </LayerWrapper>
                ) : undefined}

            </>
        );
    };
    ComponentWithErrorHandling.displayName = `withErrorHandling(${displayName})`;
    return ComponentWithErrorHandling;
}
