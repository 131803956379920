/* tslint:disable */
/* eslint-disable */
/**
 * VWN Video streaming service docs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.6-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailBounceDto } from './EmailBounceDto';
import {
    EmailBounceDtoFromJSON,
    EmailBounceDtoFromJSONTyped,
    EmailBounceDtoToJSON,
} from './EmailBounceDto';

/**
 * The new object.
 * @export
 * @interface ParticipantDto
 */
export interface ParticipantDto {
    /**
     * 
     * @type {number}
     * @memberof ParticipantDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof ParticipantDto
     */
    eventId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    acceptedToS?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantDto
     */
    lastLogin?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    unsubscribed?: boolean;
    /**
     * 
     * @type {EmailBounceDto}
     * @memberof ParticipantDto
     */
    blockListEntry?: EmailBounceDto;
    /**
     * 
     * @type {Date}
     * @memberof ParticipantDto
     */
    reminderSent?: Date;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    firstname?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ParticipantDto
     */
    organizer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ParticipantDto
     */
    eventName?: string;
}

/**
 * Check if a given object implements the ParticipantDto interface.
 */
export function instanceOfParticipantDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ParticipantDtoFromJSON(json: any): ParticipantDto {
    return ParticipantDtoFromJSONTyped(json, false);
}

export function ParticipantDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParticipantDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'eventId': !exists(json, 'eventId') ? undefined : json['eventId'],
        'acceptedToS': !exists(json, 'acceptedToS') ? undefined : json['acceptedToS'],
        'lastLogin': !exists(json, 'lastLogin') ? undefined : (new Date(json['lastLogin'])),
        'unsubscribed': !exists(json, 'unsubscribed') ? undefined : json['unsubscribed'],
        'blockListEntry': !exists(json, 'blockListEntry') ? undefined : EmailBounceDtoFromJSON(json['blockListEntry']),
        'reminderSent': !exists(json, 'reminderSent') ? undefined : (new Date(json['reminderSent'])),
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'firstname': !exists(json, 'firstname') ? undefined : json['firstname'],
        'organizer': !exists(json, 'organizer') ? undefined : json['organizer'],
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
    };
}

export function ParticipantDtoToJSON(value?: ParticipantDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'eventId': value.eventId,
        'acceptedToS': value.acceptedToS,
        'lastLogin': value.lastLogin === undefined ? undefined : (value.lastLogin.toISOString()),
        'unsubscribed': value.unsubscribed,
        'blockListEntry': EmailBounceDtoToJSON(value.blockListEntry),
        'reminderSent': value.reminderSent === undefined ? undefined : (value.reminderSent.toISOString()),
        'lastname': value.lastname,
        'firstname': value.firstname,
        'organizer': value.organizer,
        'eventName': value.eventName,
    };
}

