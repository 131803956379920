import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import { RouterProvider, useParams } from "react-router";
import { Navigate, createBrowserRouter } from "react-router-dom";
import { isDevEnv } from "./ApplicationContext";
import { UserBackend, UserContext } from "./utils/backend";
import { withD6ErrorHandling } from "./utils/d6ErrorHandler";
import { JwtUserDto, WithErrorDisplayProps } from "./utils/types";

const Home = React.lazy(() => import("./views/home"));
const Imprint = React.lazy(() => import("./views/imprint"));
const Login = React.lazy(() => import("./views/login"));
const PrivacyView = React.lazy(() => import("./views/privacy"));
const ToSView = React.lazy(() => import("./views/tos"));
const EventView = React.lazy(() => import("./views/event"));
const Error404View = React.lazy(() => import("./views/error404"));
const UnsubscribeView = React.lazy(() => import("./views/unsubscribe"));
const CookiesView = React.lazy(() => import("./views/cookies"));
const LicenseView = React.lazy(() => import("./views/license"));
const AdminView = React.lazy(() => import("./views/admin"));

const getAccessToken = async (): Promise<string> => {
    const currentSession = await fetchAuthSession();
    const jwtToken = currentSession.tokens?.accessToken.toString();
    if (!jwtToken) {
        throw "token not available";
    }
    return jwtToken;
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const user = useContext(UserContext);
    return user ? children : <Login />;
};

const App: React.FunctionComponent<WithErrorDisplayProps> = (props: WithErrorDisplayProps) => {
    const [user, setUser] = useState<JwtUserDto | undefined>();
    const [amplifyConfigured, setAmplifyConfigured] = useState(false);

    const Event: React.FC = () => {
        const { id } = useParams();
        if (!id) {
            return (<Error404View />);
        }
        return (
            <EventView eventUrlId={id} />
        );
    };

    const Unsubscribe: React.FC = () => {
        const { id } = useParams();
        if (!id) {
            return (<Error404View />);
        }
        return (
            <UnsubscribeView unsubscribeCode={id} />
        );
    };

    Hub.listen("auth", ({ payload }) => {
        switch (payload.event) {
            case "customOAuthState": {
                log.debug("custom state returned from CognitoHosted UI payload: %o", payload);
                const path = payload.data || "/";
                const url = new URL(window.location.href);
                url.pathname = path;
                url.search = "";
                window.location.href = url.toString();
                break;
            }
        }
    });

    useEffect(() => {
        if (isDevEnv) {
            log.warn("running in dev mode - using mock user.");
            // this will bypass authentication on local development environment
            setUser({
                email: "test@mail.com",
                familyName: "test",
                givenName: "test",
                admin: true,
                organiser: false,
                accessToken: () => Promise.resolve("test")
            });
        } else {
            fetch("/config.json", {
                cache: "no-cache"
            }).then((response) => {
                response.json().then((json) => {
                    Amplify.configure({
                        Auth: {
                            Cognito: {
                                ...json.Cognito
                            }
                        }
                    });

                    setAmplifyConfigured(true);
                    log.debug("amplify now configured!");
                }).catch(reason => log.warn("error while fetching config: %o", reason));
            }
            );
        }
    }, [props.onError]);
    useEffect(() => {
        if (amplifyConfigured) {
            log.debug("fetching auth session...");
            fetchAuthSession().then((session) => {
                log.debug("got session: %o", session);
                const accessToken = session.tokens?.accessToken.toString();
                if (accessToken) {
                    log.debug("accessToken: %o", accessToken);
                    const userBackend = new UserBackend(props.onError, getAccessToken);
                    userBackend.getMe().then(user => setUser({
                        ...user,
                        accessToken: getAccessToken
                    })).catch(() => log.warn("error calling me backend"));
                }
            }).catch(reason => log.info("not signed in: %o", reason));
        } else {
            log.debug("amplify not yet configured!");
        }
    }, [amplifyConfigured, props.onError]);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <RequireAuth><Home /></RequireAuth>
        },
        {
            path: "/login",
            element: user ? <Navigate to="/" /> : <Login />
        },
        {
            path: "/admin/*",
            element: (
                <RequireAuth>
                    {(user?.admin || user?.organiser) ? (
                        <AdminView />
                    ) : (
                        <Error404View />
                    )}
                </RequireAuth>
            )
        },
        {
            path: "/legal",
            children: [
                { path: "imprint", element: <Imprint /> },
                { path: "tos", element: <ToSView /> },
                { path: "privacy", element: <PrivacyView /> },
                { path: "cookies", element: <CookiesView /> },
                { path: "license", element: <LicenseView /> },
            ]
        },
        {
            path: "/event/:id",
            element: <RequireAuth><Event /></RequireAuth>
        },
        {
            path: "/unsubscribe/:id",
            element: <Unsubscribe />
        },

    ]);

    return (
        <UserContext.Provider value={user}>
            <RouterProvider router={router} />
        </UserContext.Provider>
    );
};

export default withD6ErrorHandling(App);
